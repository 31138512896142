import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { breakpoints, colours } from "design-kit"

import DualImageSection from "./DualImageSection"
import Footer from "../Shared/Footer"
import Hero from "../Shared/Hero"
import ImageSection from "../Shared/ImageSection"
import Navbar from "../Shared/Navbar"
import {
  Main,
  HeroHeading as DefaultHeroHeading,
  Paragraph,
  Props,
  SectionHeading,
  colors,
} from "../Shared"

import curlingImg from "./assets/curling.jpg"
import epdImg from "./assets/epd.jpg"
import foodImg from "./assets/food.jpg"
import heroImg from "./assets/hero.jpg"
import massageImg from "./assets/massage.jpg"
import officeImg from "./assets/office.jpg"
import officeDrinksImg from "./assets/office-drinks.jpg"
import people1Img from "./assets/people-1.jpg"
import people2Img from "./assets/people-2.jpg"
import puppiesImg from "./assets/puppies.jpg"
import toughMudderImg from "./assets/tough-mudder.jpg"
import xMasJumperImg from "./assets/xmas-jumper.jpg"

const HeroHeading = styled(DefaultHeroHeading)`
  ${breakpoints.tablet`
    width: 65%;
  `}
`

const MobileReverseImageSection = styled(ImageSection)`
  flex-direction: column-reverse;
`

const View: React.FunctionComponent<Props> = ({ location }) => (
  <React.Fragment>
    <Navbar location={location} />

    <Main>
      <Hero backgroundImg={heroImg}>
        <HeroHeading>The benefits</HeroHeading>
      </Hero>

      <ImageSection
        variant="normal"
        image={people1Img}
        backgroundColor={colors.yellow}
      >
        <Paragraph>
          We want to live our values in everything we do. That means working
          with excellent, supportive people. It means feeling excited about your
          work. Building things you’re proud of, and having a good time doing
          it.
        </Paragraph>

        <Paragraph>
          It also means outstanding employee benefits, tools, training, support,
          perks and socials – everything you need to be successful in your own
          way.
        </Paragraph>
      </ImageSection>

      <MobileReverseImageSection
        variant="reverse"
        image={officeDrinksImg}
        backgroundColor={colours.offBlack}
      >
        <SectionHeading
          css={css`
            color: ${colours.white};
          `}
        >
          Your career
        </SectionHeading>

        <Paragraph
          css={css`
            color: ${colours.white};
          `}
        >
          We’ll support you in creating your own Personal Growth Plan, so you
          can take your career in the direction you want it to go.
        </Paragraph>

        <Paragraph
          css={css`
            color: ${colours.white};
          `}
        >
          We’re open and transparent and run Habito that way. You’ll regularly
          hear about our business performance, be able to read every single
          customer review we get, and get an update on our company Objectives
          and Key Results (OKRs) every week. You’ll see the real-world effects
          your work is having, day to day.
        </Paragraph>
      </MobileReverseImageSection>

      <DualImageSection
        leftBackgroundImg={foodImg}
        rightBackgroundImg={massageImg}
      />

      <ImageSection variant="normal" image={epdImg}>
        <SectionHeading
          css={css`
            margin-top: 48px;
          `}
        >
          Your team
        </SectionHeading>

        <Paragraph>
          The whole company gets together virtually, once a week, every week to
          share updates, welcome new joiners, celebrate milestones, demo
          products, reveal new TV ads, and launch new competitions and
          initiatives.
        </Paragraph>

        <Paragraph>
          We also get together in real time outside of work, both in our teams
          and as a company for quarterly all hands, quiz nights, team building
          events and company celebrations.
        </Paragraph>

        <Paragraph
          css={css`
            margin-bottom: 48px;
          `}
        >
          Every quarter, we honour teammates who show our values with a HERBie
          award - for those who demonstrate having backbone, empathising with
          purpose, being 100% responsible and building better things.
        </Paragraph>
      </ImageSection>

      <DualImageSection
        leftBackgroundImg={puppiesImg}
        rightBackgroundImg={xMasJumperImg}
      />

      <MobileReverseImageSection
        variant="reverse"
        image={toughMudderImg}
        backgroundColor={colors.aquamarine}
      >
        <SectionHeading>Your wellbeing</SectionHeading>

        <Paragraph>
          We host regular inspirational guest speakers who offer us fresh
          perspectives on the world around us. To give you an idea, we’ve
          welcomed experts in financial wellbeing, founders from fellow
          start-ups, and athletes who’ve sailed across the Atlantic.
        </Paragraph>
      </MobileReverseImageSection>

      <DualImageSection
        leftBackgroundImg={people2Img}
        rightBackgroundImg={curlingImg}
      />

      <ImageSection variant="normal" image={officeImg}>
        <SectionHeading>Your benefits</SectionHeading>

        <Paragraph>Enhanced company pension.</Paragraph>

        <Paragraph>Enhanced maternity and paternity leave.</Paragraph>

        <Paragraph>Life insurance as standard.</Paragraph>
      </ImageSection>
    </Main>

    <Footer />
  </React.Fragment>
)

export default View

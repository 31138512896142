import React from "react"

import styled from "@emotion/styled"

import { breakpoints } from "design-kit"

const DualImageSection = styled.section`
  display: flex;
  flex-direction: column;

  ${breakpoints.tablet`
    flex-direction: row;
  `}
`

const ImageContainer = styled.div<{ backgroundImage: string }>`
  width: 100%;
  min-height: 320px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(${props => props.backgroundImage});

  ${breakpoints.tablet`
    min-height: 480px;
  `}
`

interface Props {
  leftBackgroundImg: string
  rightBackgroundImg: string
}

const View: React.FunctionComponent<Props> = ({
  leftBackgroundImg,
  rightBackgroundImg,
}) => (
  <DualImageSection>
    <ImageContainer backgroundImage={leftBackgroundImg} />
    <ImageContainer backgroundImage={rightBackgroundImg} />
  </DualImageSection>
)

export default View

import React from "react"
import { PageRendererProps } from "gatsby"
import { WrappedBase } from "../../shared-components/Shared/WrappedBase"

import Page from "../../views/Culture/YouAndHabito"

import config from "../../../config.json"

const pageName = "our_culture_you_an_habito"

const View: React.FunctionComponent<PageRendererProps> = ({ location }) => (
  <WrappedBase
    metaTitle="Habito | Our culture"
    metaDescription="We're the Treating Customers Fairly Champion of 2023 as voted for in the British Bank Awards. We're here to make mortgages easier - no paperwork, no stress and no fees."
    canonicalUrl="https://www.habito.com/culture"
    noIndex={true}
    pageName={pageName}
    intercom={false}
    config={config}
    head={null}
  >
    <Page location={location} />
  </WrappedBase>
)

export default View
